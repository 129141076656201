<template>
    <div class="profile-photo-layout" @click="$emit('clickProfile')">
        <div class="profile-overlay" v-if="content.accept_status === 'rejected'" />
        <div class="card flex-row items-center" :class="{ 'brand-border': ['accepted', 'manner'].includes(content.accept_status) }">
            <div class="flex-wrap">
                <div v-if="!$store.getters.isMobile" class="image-container">
                    <div class="flex card-small" :key="image" v-for="image in images">
                        <div
                            class="img-profile flex-wrap"
                            v-lazy:background-image="image"
                            @click.stop="$emit('clickImage', image)"
                            @error="$event.target.src = $blankProfile"
                        />
                    </div>
                </div>
                <div v-else class="image-container-mobile">
                    <div class="flex card-small" :key="images[0]">
                        <div
                            class="img-profile flex-wrap"
                            v-lazy:background-image="images[0]"
                            @click.stop="$emit('clickImage', images[0])"
                            @error="$event.target.src = $blankProfile"
                        />
                    </div>
                </div>
                <div v-if="$store.getters.isMobile" class="photo-counter-tag">{{ images.length }}장</div>
            </div>
            <div class="flex-fill text-profile">
                <div
                    v-if="['accepted', 'manner'].includes(content.accept_status) && !$store.getters.isMobile"
                    class="profile-up-accepted-pc flex-wrap m-b-8"
                >
                    소개해주세요 <br>
                    <span class="f-11">수락 사유: {{this.content.reasons}}</span>
                </div>
                <div
                    v-else-if="['accepted', 'manner'].includes(content.accept_status) && $store.getters.isMobile"
                    class="profile-up-accepted flex-wrap m-b-8"
                >
                    소개해주세요 <br>
                    <span class="f-11">수락 사유: {{this.content.reasons}}</span>
                </div>
                <div
                    v-else-if="content.accept_status === 'rejected' && !$store.getters.isMobile"
                    class="profile-up-rejected-pc flex-wrap m-b-8"
                >
                    패스했습니다 <br>
                    <span class="f-11">패스 사유: {{this.content.reject_reason || this.content.reasons}}</span>
                </div>
                <div
                    v-else-if="content.accept_status === 'rejected' && $store.getters.isMobile"
                    class="profile-up-rejected flex-wrap m-b-8"
                >
                    패스했습니다 <br>
                    <span class="f-11">패스 사유: {{this.content.reject_reason || this.content.reasons}}</span>
                </div>
                <div v-else class="profile-up-default flex-wrap">{{ user.name }}, {{ user.birthday | asAge }}</div>
                <div
                    v-if="!$store.getters.isMobile"
                    class="profile-down-pc flex-wrap text-ellipsis"
                    v-html="
                        accept_status === ''
                            ? `${this.$options.filters.noDetailRegion(user.region)}<br>${user.job}`
                            : `${user.nickname}, ${$options.filters.asAge(user.birthday)}`
                    "
                />
                <div
                    v-else
                    class="profile-down flex-wrap text-ellipsis"
                    v-html="
                        accept_status === ''
                            ? `${this.$options.filters.noDetailRegion(user.region)}<br>${user.job}`
                            : `${user.nickname}, ${$options.filters.asAge(user.birthday)}`
                    "
                />
            </div>
            <div class="flex-wrap functions chat-btn">
                <div class="button">
                    <ChatButton
                        class="button"
                        :class="friendType"
                        :user="user"
                        :source_id="$store.getters.chat.user.id"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProfilePhotoLayout',
    props: ['message'],
    computed: {
        images() {
            return (this.$mustParse(this.message.content) || {}).urls
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        user() {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.content.id = this.content.source_id

            // ChatButton 코드를 보면, $$chatId가 있어야 편지지가 아닌 말풍선으로 보임
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.content.$$chatId = (this.$getChat(this.content.id) || {}).id
            return this.content
        },
        friendType() {
            return this.$options.filters.friendType(this.user)
        },
        accept_status() {
            if (this.content.accept_status === 'accepted') {
                return '수락'
            } else if (this.content.accept_status === 'manner') {
                return '매너'
            } else if (this.content.accept_status === 'rejected') {
                return '패스'
            } else {
                return ''
            }
        },
        // friendStatus() {
        //     if (this.requestingFriend.status < 0)
        //         return '거절'
        //     else if (this.requestingFriend.status === 0 && this.requestingFriend.pending)
        //         return '수락'
        //     else if (this.requestingFriend.status === 0 && !this.requestingFriend.pending)
        //         return '미확인'
        //     else
        //         return ''

        // },
        // requestingFriend() {
        //     if (!this.$store.getters.requestingFriends) return {}
        //     return (this.$store.getters.requestingFriends.filter(f =>
        //         f.user_id === this.user.id && f.recommend_user_id === this.$store.getters.chat.user.id)[0]) || {}
        // },
    },
}
</script>
