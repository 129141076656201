<template>
    <div class="action-photo">
        <div class="section-photos" :class="{ 'multi-photo': message.mtype === 'multi-photo' }">
            <div class="timestamp" v-if="me && message.mtype !== 'multi-photo'">
                {{ message.created_at | formatDate('A h:mm') }}
            </div>
            <div v-if="message.mtype === 'photo'" class="photo-wrapper">
                <img
                    class="img-cover flex-wrap"
                    :src="imageUrl"
                    @click="onClickImage(imageUrl)"
                    @error="imageUrl = $blankProfile"
                />
                <div class="icon-wrap">
                    <div v-if="content.source_id && content.both_like" class="both-like">서로호감</div>
                    <div v-if="content.is_manner" class="manner-like-icon">매너수락</div>
                </div>
                <div class="profile-wrapper">
                    <div v-if="content.source_id && !message.$$hideFunction" class="functions">
                        <div class="button m-b-8" v-if="isPremiumAgent && isPremiumUser" @click="sendSalesTemplate">
                            <img class="send-sales-template" src="@/assets/images/mark_chat_read.svg" />
                        </div>
                        <div class="button m-b-8" @click="onCLickInstantChat">
                            <i class="material-icons">chat_bubble</i>
                        </div>
                        <div class="button m-b-8" @click="clickCheckPhotoMessage">
                            <i class="material-icons">done</i>
                        </div>
                        <!-- 프로필 확인 버튼 -->
                        <div class="button" @click="onClickProfile">
                            <i class="zmdi zmdi-account-box" />
                        </div>
                        <!-- 해당 유저 채팅방으로 이동 -->
                        <div class="button m-t-8">
                            <ChatButton class="button" :class="friendType" :user="user" :source_id="chat.user.id" />
                        </div>
                    </div>
                    <div v-if="content.source_id" class="photo-profile">
                        <div class="profile-up m-b-4">{{ user.name || '-' }}({{ user.id }}), {{ user.birthday | asAge }}세</div>
                        <div class="profile-down">{{ user.region }}, {{ user.job }}</div>
                    </div>
                </div>
            </div>
            <ProfilePhotoLayout
                v-else-if="message.mtype === 'profile-photo'"
                @clickImage="onClickImage"
                @clickProfile="onClickProfile"
                :message="message"
            />
            <template v-else-if="message.mtype === 'multi-photo'">
                <MultiPhotoLayout class="multi-photo" @click="onClickImage" :message="message" />
                <div v-if="content.source_id && !message.$$hideFunction" class="functions multi-photo">
                    <div class="button">
                        <ChatButton class="button" :class="friendType" :user="user" :source_id="chat.user.id" />
                    </div>
                    <div class="button m-t-8" @click="onClickProfile">
                        <i class="zmdi zmdi-account-box" />
                    </div>
                </div>
            </template>
            <template v-else-if="message.mtype === 'self-profile-photo'">
                <ActionDefaultLayout :message="message">
                    <div class="action-noti">
                        <div class="title">프로필을 보냈습니다.</div>
                        <div class="body">주선시 상대방에게 보여질 프로필임을 안내해주세요.</div>
                    </div>
                </ActionDefaultLayout>
            </template>

            <div class="timestamp" v-if="!me && message.mtype !== 'multi-photo'">
                {{ message.created_at | formatDate('A h:mm') }}
            </div>
        </div>
        <div v-if="message.mtype === 'multi-photo'" class="source-info-with-timestamp">
            <div class="flex-row items-end">
                <div class="timestamp" v-if="me">{{ message.created_at | formatDate('A h:mm') }}</div>
                <div class="source-info hor-separated" v-if="user">
                    <span
                        ><i class="zmdi zmdi-account-box" />{{ nameOrNickname }} ({{ user.birthday | asAge }}세,
                        {{ user.height }}cm)</span
                    >
                    <span>{{ user.region }}</span>
                    <span>{{ user.job }}</span>
                </div>
                <div class="timestamp" v-if="!me">{{ message.created_at | formatDate('A h:mm') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import ProfilePhotoLayout from './ProfilePhotoLayout'
import MultiPhotoLayout from './MultiPhotoLayout'
import chatService from '@/services/chat'
import ActionDefaultLayout from '../ActionDefaultLayout'

export default {
    name: 'ActionPhoto',
    props: ['message'],
    components: { ProfilePhotoLayout, MultiPhotoLayout, ActionDefaultLayout },
    watch: {
        content(newVal) {
            if (newVal) this.imageUrl = newVal.url
        },
    },
    computed: {
        nameOrNickname() {
            if (!this.user) return '-'

            return this.user.nickname
        },
        me() {
            return this.message.user_id === this.$store.getters.agent.user_id
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        user() {
            if (!this.content || !this.content.birthday) return {}

            // const content = this.content
            // content.id = this.content.source_id
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.content.id = this.content.source_id

            // ChatButton 코드를 보면, $$chatId가 있어야 편지지가 아닌 말풍선으로 보임

            // content.$$chatId = (this.$getChat(this.content.id) || {}).id
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.content.$$chatId = (this.$getChat(this.content.id) || {}).id
            // return content
            return this.content
        },
        photos() {
            const messages = (this.chat || {}).$$messages
            let photos = []
            const photoMessages = (messages || []).filter(m => m.mtype.includes('photo')) || []
            photoMessages.forEach(m => {
                const c = this.$mustParse(m.content)
                if (c.url) photos.push(c.url)
                if (c.urls) photos = photos.concat(c.urls.reverse())
            })

            return photos.reverse()
        },
        nicknames() {
            const messages = (this.chat || {}).$$messages
            const nicknames = []
            const photoMessages = (messages || []).filter(m => m.mtype.includes('photo')) || []
            photoMessages.forEach(m => {
                const c = this.$mustParse(m.content)
                if (c.url) nicknames.push(`${c.nickname}, ${this.$options.filters.asAge(c.birthday)}`)
                if (c.urls) {
                    for (let i = 0; i < c.urls.length; i++) {
                        nicknames.push(`${c.nickname}, ${this.$options.filters.asAge(c.birthday)}`)
                    }
                }
            })

            return nicknames.reverse()
        },
        chat() {
            return this.$store.getters.chat
        },
        friendType() {
            return this.$options.filters.friendType(this.user)
        },
        autoTexts() {
            return this.$store.getters.autoTextsAllCategory || []
        },
        isPremiumAgent() {
            return this.$store.getters.agent.is_premium
        },
        isPremiumUser() {
            return (this.chat.user || {}).$$premium
        },
    },
    data: () => ({
        imageUrl: null,
    }),
    mounted() {
        this.imageUrl = this.content.url
    },
    methods: {
        async clickCheckPhotoMessage() {
            const payload = {
                user_id: this.chat.user.id,
                source_id: this.content.source_id,
            }

            const res = await chatService.checkPhotoMessage(payload)
            if (res.res === 'success') {
                this.$toast.success(res.msg)
            }
        },
        async onCLickInstantChat() {
            await this.$modal
                .basic({
                    body: '대화방을 오픈하시겠습니까? 5일간 대화방이 생성됩니다.(',
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: '열기',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(async idx => {
                    if (idx === 1) {
                        const payload = {
                            source_id: this.content.source_id,
                        }

                        const res = await chatService.makeInstantChat(payload)
                        console.log(res.res)

                        if (res.res === 'success') {
                            this.$toast.success(res.msg)
                        } else {
                            this.$toast.error(res.msg)
                        }
                    }
                })
        },
        async onClickProfile() {
            try {
                await this.$store.dispatch('loadIntroducingUser', this.content.source_id)
                if (this.$store.getters.isMobile) {
                    this.$router.push({
                        name: 'UserDetailPage',
                        params: {
                            userId: this.content.source_id,
                            from: 'ActionPhoto',
                        },
                    })
                }
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        onClickImage(pivotImage) {
            if (this.message.$$hideFunction) return
            const selectedIndex = this.photos.indexOf(pivotImage)
            this.$modal.images({
                images: this.photos,
                selectedIndex,
                nicknames: this.nicknames,
            })
        },
        async sendSalesTemplate() {
            const payload = {
                target_id: this.content.source_id,
                force: false,
            }

            if (
                !this.autoTexts.sales_template ||
                this.autoTexts.sales_template.filter(t => t.title === `세일즈템플릿_${this.chat.user.id}`).length === 0
            ) {
                this.$toast.error('개인별 세일즈 템플릿 작성 후 이용해주세요')
                return
            }

            try {
                this.$loading(true)
                const data = await chatService.sendSalesTemplate(this.chat.id, payload)
                this.$loading(false)

                if (data.type === 0) {
                    this.$toast.success(data.message)
                } else if (data.type === 3) {
                    this.$modal
                        .basic({
                            title: 'MODAL_DEFAULT_TITLE',
                            body: data.message,
                            buttons: [
                                {
                                    label: 'CANCEL',
                                    class: 'btn-default',
                                },
                                {
                                    label: 'SEND',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        .then(async idx => {
                            if (idx === 1) {
                                const newPayload = {
                                    target_id: payload.target_id,
                                    force: true,
                                }
                                const data = await chatService.sendSalesTemplate(this.chat.id, newPayload)
                                this.$toast.success(data.message)
                            }
                        })
                } else if (data.type) {
                    this.$modal.basic({
                        title: 'NOTICE',
                        body: data.message,
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    // this.$alert(data.message)
                } else {
                    this.$modal.basic({
                        title: 'NOTICE',
                        body: data.msg,
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    // this.$alert(data.msg)
                }
            } catch (e) {
                this.$loading(false)
                if (e.data.msg) {
                    this.$modal.basic({
                        title: 'NOTICE',
                        body: e.data.msg,
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.send-sales-template {
    width: 21px;
    height: 20px;
    // background-image: linear-gradient(134deg, #f3d27a, #edbf5e 31%, #e9b04e 71%, #f3d27a);
}
</style>
