var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"multi-photo-layout"},[(_vm.numImages === 1)?_c('img',{staticClass:"img-cover flex-wrap",attrs:{"src":_vm.images[0]},on:{"click":function($event){return _vm.$emit('click', _vm.images[0])},"error":_vm.onErrorOneImage}}):_c('div',{staticClass:"card"},[(_vm.rowOne.length > 0)?_c('div',{staticClass:"grid-row-1",class:{
                two: _vm.rowOne.length === 2,
                three: _vm.rowOne.length === 3,
            }},_vm._l((_vm.rowOne),function(url){return _c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(url),expression:"url",arg:"background-image"}],key:url,on:{"click":function($event){return _vm.$emit('click', url)}}})}),0):_vm._e(),(_vm.rowTwo.length > 0)?_c('div',{staticClass:"grid-row-2",class:{
                two: _vm.rowTwo.length === 2,
                three: _vm.rowTwo.length === 3,
            }},_vm._l((_vm.rowTwo),function(url){return _c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(url),expression:"url",arg:"background-image"}],key:url,on:{"click":function($event){return _vm.$emit('click', url)}}})}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }