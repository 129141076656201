<template>
    <div class="multi-photo-layout">
        <img
            v-if="numImages === 1"
            class="img-cover flex-wrap"
            :src="images[0]"
            @click="$emit('click', images[0])"
            @error="onErrorOneImage"
        />
        <div v-else class="card">
            <div
                v-if="rowOne.length > 0"
                class="grid-row-1"
                :class="{
                    two: rowOne.length === 2,
                    three: rowOne.length === 3,
                }"
            >
                <div @click="$emit('click', url)" :key="url" v-for="url in rowOne" v-lazy:background-image="url" />
            </div>
            <div
                v-if="rowTwo.length > 0"
                class="grid-row-2"
                :class="{
                    two: rowTwo.length === 2,
                    three: rowTwo.length === 3,
                }"
            >
                <div @click="$emit('click', url)" :key="url" v-for="url in rowTwo" v-lazy:background-image="url" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MultiPhotoLayout',
    props: ['message'],
    data: () => ({
        rowOne: [],
        rowTwo: [],
    }),
    watch: {
        images() {
            this.init()
        },
    },
    computed: {
        images() {
            return (this.$mustParse(this.message.content) || {}).urls
        },
        numImages() {
            return (this.images || []).length
        },
    },
    methods: {
        onErrorOneImage() {
            this.images[0] = this.$blankProfile
        },
        init() {
            const len = (this.images || []).length
            try {
                if (len === 4) {
                    this.rowOne = this.images.slice(0, 2)
                    this.rowTwo = this.images.slice(2, 4)
                } else {
                    const pivot = len > 3 ? 3 : len
                    this.rowOne = this.images.slice(0, pivot)
                    this.rowTwo = this.images.slice(pivot, 6)
                }
            } catch (e) {}
        },
        onClickMultiPhotoLayout() {
            this.$modal.images({
                images: this.images,
            })
        },
    },
    mounted() {
        this.init()
    },
}
</script>
